<template>
  <div>
      <div class="content-filters">
        <div class="content-filter-show-for">
        <v-skeleton-loader
          class="mx-auto"
          width="250"
          type="text"
        ></v-skeleton-loader>
        </div>
        <div class="content-filter-date" @click="menu = true">
        <v-skeleton-loader
          class="mx-auto"
          width="250"
          type="text"
        ></v-skeleton-loader>
        </div>
        <div class="content-filter-menu-btn">
        <v-skeleton-loader
          class="mx-auto"
          width="auto"
          type="button"
        ></v-skeleton-loader>
        </div>
      </div>
        <v-skeleton-loader
          class="mx-auto"
          width="aout"
          type="table"
        ></v-skeleton-loader>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dates: [],
      menu: false,
      bAZ: false,
      bZA: false,
      bEntries: false,
      bExits: false,
      bMajorMinor: false,
      bMinorMajor: false,
      headers: [
        {
          text: "Empresa/Persona",
          sortable: false,
          value: "sName",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Fecha",
          sortable: false,
          value: "sDate",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Transacción",
          sortable: false,
          value: "sTransation",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
        {
          text: "Cantidad procesada",
          value: "sProcessedQuantity",
          class: "global-header-table",
          cellClass: "global-body-table",
        },
      ],
      desserts: [
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
        {
          sName: "De acero",
          sDate: "16/12/21",
          sTransation: "Entrada",
          sProcessedQuantity: "+ 2.344,45 kg",
        },
      ],
    };
  },
};
</script>
<style scoped>
.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}
.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}
.content-filters {
  display: flex;
  width: 100%;
}
.content-filter-show-for {
  width: 25%;
  margin-right: 15px;
}

.content-filter-date {
  width: 25%;
}
.content-filter-date .v-text-field {
  padding-top: 0px !important;
}

.content-filter-menu-btn {
  width: 50%;
  justify-content: end;
  display: flex;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: 650px;
  min-width: 650px;
  max-width: 650px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-menu {
  width: 40px;
}
/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filters {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter-show-for {
    width: 100%;
    margin-right: 0px;
  }

  .content-filter-date {
    width: 100%;
  }

  .content-filter-menu-btn {
    width: 100%;
  }
  .btn-filter-menu {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-filter-show-for {
    width: 40%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 45%;
  }

  .content-filter-menu-btn {
    width: 15%;
    justify-content: end;
    display: flex;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-filter-show-for {
    width: 25%;
    margin-right: 15px;
  }

  .content-filter-date {
    width: 30%;
  }

  .content-filter-menu-btn {
    width: 45%;
    justify-content: end;
    display: flex;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>